.App {
  text-align: center;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}


.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: white;
  opacity: 0.5;
}

.App-link:hover {
  opacity: 1;
}

.textCopy {
  margin-top: 40px;
}

.gradient-background {
  background: linear-gradient(319deg,#ffa1a1,#ff4d4d,#ff5f9a,#ffca5f,#ff2d75);
  background-size: 300% 300%;
  animation: gradient-animation 10s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}